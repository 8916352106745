import React, { useState } from "react";
import styled from "styled-components";
import EzReadMore from "../components/ReUsables/EzReadMore";
import { RespContainer } from "../styles/common";
import { FontelloIcon } from "../styles/CommonComponents";
import { Colors, Devices } from "../styles/variables";

const Sitemap: React.FC<any> = ({ pageContext, ...props }) => {
  const sitemapPages = pageContext.data.sitemap.pages;
  const blogPosts = pageContext.data.sitemap.posts;

  const getParents = (array) =>
    array.reduce(
      (acc, { wordpress_parent: parent, wordpress_id: id, title }) => {
        if (parent == 0) {
          if (!acc[id]) acc[id] = [];
          acc[id].push(title);
        }
        return acc;
      },
      {}
    );

  const PARENTS = getParents(sitemapPages);

  const groupBy = (objectArray, property) =>
    objectArray.reduce((acc, obj) => {
      let key = obj[property] == 0 ? "top_level_pages" : PARENTS[obj[property]];
      if (!acc[key]) acc[key] = [];
      acc[key].push(obj);
      return acc;
    }, {});

  const PARENTS_SLUGS = {};
  const groupedPages = groupBy(sitemapPages, "wordpress_parent");
  groupedPages.top_level_pages = groupedPages.top_level_pages.filter((page) => {
    PARENTS_SLUGS[page.title] = page.slug;
    if (page.slug === "blog") {
      groupedPages.Blog = blogPosts;
      return false;
    }
    return !groupedPages[page.title];
  });

  return (
    <>
      <RespContainer>
        <Title>Sitemap</Title>
        <SiteMapWrapper>
          {Object.entries(groupedPages).map(([title, pages], id) => {
            return PagesHOC(
              title,
              PARENTS_SLUGS,
              pages,
              id,
              title === "top_level_pages"
            )(Pages);
          })}
        </SiteMapWrapper>
      </RespContainer>
    </>
  );
};

export default Sitemap;

const PagesHOC = (title, slugs, pages, id, topLevelPages = false) => (
  ChildComponent
) => {
  const ChildProps = { pages: pages, key: id };

  if (topLevelPages) return <ChildComponent {...ChildProps} />;

  const [toggle, setToggle] = useState(true);

  return (
    <List key={`${title}${id}`}>
      <ListItem onClick={() => setToggle(!toggle)} clickable>
        <Anchor href={`/${slugs[title]}`}>{title}</Anchor>
        <IconWrapper>
          <FontelloIcon icon={`${toggle ? "up" : "down"}-open`} />
        </IconWrapper>
      </ListItem>
      {toggle && <ChildComponent {...ChildProps} />}
    </List>
  );
};

const Pages: React.FC<any> = ({ pages }) => {
  return (
    <List>
      {pages.map((page, idx) => (
        <ListItem key={`${page.id}${idx}`}>
          <Anchor
            href={`${
              page?.path
                ? page?.slug === "home"
                  ? "/"
                  : page.path
                : `/blog/${page.slug}`
            }`}
          >
            {page.title}
          </Anchor>
        </ListItem>
      ))}
    </List>
  );
};

const Title = styled.h1`
  font-size: 3.125rem;
  font-weight: 500;
  margin-top: 0.75rem;
  margin-bottom: 2.25rem;

  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    font-size: 4rem;
    margin-top: 2.75rem;
  }
`;

const SiteMapWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;

  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    max-width: 520px;
    margin-bottom: 24px;
  }
`;

const List = styled.ul`
  padding: 30px 20px;
  background-color: ${Colors.gray8};
  margin: 0 0 1.5rem;
  list-style-type: none;
  width: 100%;

  > li:last-child {
    margin-bottom: 0;
  }

  > ul {
    padding-bottom: 0;
    padding-top: 0;
  }
`;

const ListItem = styled.li<{ clickable?: boolean }>`
  font-weight: 600;
  color: ${Colors.sharpBlue};
  margin-bottom: 32px;
  position: relative;

  ${({ clickable }) => clickable && `:hover { cursor: pointer}`}
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Anchor = styled.a`
  color: inherit;
`;
